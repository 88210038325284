import './styles.scss'
import React from 'react'
import { Button } from 'components'
import whatsapp from 'images/whatsapp.svg'

export default function Footer() {
  return (
    <footer id="footer">

      <div className="container get-started">
        <div className="footer-column left">
          <h1 className="section-title">Get Started</h1>
          <div className="section-caption">Join us. We’ll help you every step of the way.</div>
        </div>
        <ul className="footer-column middle">
          <div className="footer-header">Requirements</div>
          <li className="list-item">Mpesa Paybill/Till Number</li>
          <li className="list-item"><a href="https://mikrotik.com/products">Mikrotik</a>&nbsp;Core Router</li>
          <li className="list-item">Domain/Subdomain (optional)</li>
        </ul>
        <div className="footer-column right">
          <Button component='a' target='_blank' url='https://api.whatsapp.com/send/?phone=254770900066&text&app_absent=0'>
            <img src={whatsapp} alt="whatsapp icon" />
            <span>Contact Sales</span>
          </Button>
        </div>
      </div>

      <div className="container">

        <div className="footer-column">
          <div className="logo-mark">NETPAP GLOBAL ™</div>
          <p className="footer-summary">
            Manage bandwidth, billing, users, internet hotspots & notifications for your internet business.
          </p>
        </div>

        <div className="footer-column">
          <div className="footer-header">Contact Us</div>
          <a href={`mailto:info@netpap.co.ke`}>info@netpap.co.ke</a>
          <a href={`tel:+254770900066`}>+254 770 900 066</a>
          <a href={`tel:+254741835078`}>+254 741 835 078</a>
          <a className="nav-link" target="_blank" href="http://blog.netpap.co.ke/terms-and-conditions/">Terms & Conditions</a>
          <a className="nav-link" target="_blank" href="http://blog.netpap.co.ke/privacy-policy">Privacy Policy</a>
          <a className="nav-link" target="_blank" href="http://blog.netpap.co.ke/">Blog</a>
          <a className="nav-link" target="_blank" href="http://blog.netpap.co.ke/change-log">Change Log</a>
          <a className="nav-link" target="_blank" href="http://blog.netpap.co.ke/docs">Documentation</a>
        </div>

        <div className="footer-column">
          <div className="footer-header">Socials</div>
          <a target='_blank' rel='noreferrer' href={`https://www.facebook.com/netpapwifi/`}>Facebook</a>
          <a target='_blank' rel='noreferrer' href={`https://twitter.com/NetpapGlobal_?s=20`}>Twitter</a>
          <a target='_blank' rel='noreferrer' href={`https://www.linkedin.com/company/netpap-global`}>Linked In</a>
          <a target='_blank' rel='noreferrer' href={`https://www.instagram.com/netpapglobal/`}>Instagram</a>
          <a target='_blank' rel='noreferrer' href={`https://api.whatsapp.com/send/?phone=254770900066&text&app_absent=0`}>Whatsapp</a>
        </div>

        <div className="footer-column">
          <div className="footer-header">Links</div>
          <a className="nav-link" href="/home#home">Home</a>
          <a className="nav-link" href="/home#about">Services</a>
          <a className="nav-link" href="/home#features">Features</a>
          <a className="nav-link" href="/home#partners">Partners</a>
          <a className="nav-link" href="/home#pricing">Pricing</a>
          <a className="nav-link" href="/home#statistics">Testimonials</a>
        </div>

      </div>
    </footer>
  )
}
