import './styles.scss'
import React, { useState } from 'react'
import { Button } from 'components'

export default function Header() {

  const [open, toggleMenu] = useState(false);

  const handleClick = () => toggleMenu(!open);

  return (
    <header id="header" className={`menu-${!open ? 'closed' : 'open'}`}>
      <div className="container" id='sticky-header'>

        <div className="nav-left">
          <a href="/" className="logo-mark">NETPAP GLOBAL ™</a>
        </div>

        <div aria-hidden="true" className={`nav-middle ${!open ? 'closed' : 'active'}`} onClick={handleClick} onKeyDown={handleClick}>
          <span className='hamburger-bar'/>
          <span className='hamburger-bar'/>
          <span className='hamburger-bar'/>
        </div>

        <nav className={`nav-right menu-${!open ? 'closed' : 'open'}`}>
          <a className="nav-link" href="/home#home">Home</a>
          <a className="nav-link" href="/home#about">Services</a>
          <a className="nav-link" href="/home#features">Features</a>
          <a className="nav-link" href="/home#partners">Partners</a>
          <a className="nav-link" href="/home#pricing">Pricing</a>
          <a className="nav-link" href="/home#statistics">Testimonials</a>
          <Button className="nav-link" url="https://stage.netpap.co.ke/auth/login">Login</Button>
        </nav>

      </div>
    </header>
  )
}
