const PricingPlans = [
  {
    caption: 'Hotspot Only',
    columns: [
      { caption: 'plan', width: '1fr' },
      { caption: 'pricing', width: '170px' },
      { caption: 'recurrent', width: '170px' },
      { caption: 'features', width: '170px' },
      { caption: 'limit', width: '170px' },
      { caption: 'billed', width: '170px' },
    ],
    rows: [
      {
        plan: 'Startup Package',
        pricing: '<em> License fee <br> USD 100</em> <br><One Off <br> ',
        recurrent: '5% per transaction<br>(i.e Ksh 20 we invoice you Ksh. 1 ) ',
        features: '<br> Full WIFI Voucher System/WIFI Top up system ie. data cap,unlimited,<br>simultaneous use,prepaid/uptime/calender packages',
        limit: '<br> 10000 monthly users',
        billed: 'Monthly',
        featured: true
      },
      {
        plan: 'Growth Stage',
        pricing: '<em>Contact us for custom billing</em> ',
        recurrent: 'Custom',
        features: 'Carrier Hotspot System',
        limit: 'Unlimited',
        billed: 'Quarterly',
        
      },
      // {
      //   plan: 'Enterprise Package',
      //   pricing: '<em>10,000</em> per transaction',
      //   discount: '5% per transaction<br>(i.e Ksh 20 we invoice you 1 bob) ',
      //   features: 'All hotspot features',
      //   limit: '5000+ Monthly users',
      //   billed: 'Monthly',
      // },

      // {
      //   plan: 'Start-Up Package',
      //   pricing: '<em>4,000</em> p.m',
      //   discount: '',
      //   features: 'All features Needed',
      //   limit: 'Upto 200 users',
      //   billed: 'Quarterly',
      // },
      // {
      //   plan: 'Scale-Up Package',
      //   pricing: '<em>5,700</em> p.m',
      //   discount: '',
      //   features: '',
      //   limit: 'Upto 400 users',
      //   billed: 'Quarterly',
      // },
      // {
      //   plan: 'Growth Package',
      //   pricing: '<em>8,200</em> p.m',
      //   discount: '',
      //   features: '',
      //   limit: 'Upto 750 users',
      //   billed: 'Quarterly',
      // },
    
      // {
      //   plan: 'Dynasty Package',
      //   pricing: '<em>10,000</em> License Fee as a One-Off<br/><em>+5%</em> per transaction',
      //   discount: '',
      //   features: 'All features.',
      //   limit: 'Unlimited users',
      //   billed: 'Monthly',
      // },
    ]
  },
  {
    caption: 'PPPoE Management',
    columns: [
      { caption: 'plan', width: '1fr' },
      { caption: 'pricing', width: '170px' },
      { caption: 'discount', width: '170px' },
      { caption: 'features', width: '170px' },
      { caption: 'limit', width: '170px' },
      { caption: 'billed', width: '170px' },
    ],
    rows: [
      {
        plan: 'Startup Package',
        pricing: '<em> USD 0.52  per active user</em> p.m',
        discount: 'USD 10 One off',
        features: 'PPPoE',
        limit: 'Upto 1000 users',
        billed: 'Monthly',
        featured: true
      },
      {
        plan: 'Growth Stage',
        pricing: '<em>Contact us for custom billing</em> ',
        discount: 'Custom',
        features: 'PPPOE',
        limit: 'Unlimited',
        billed: 'Quarterly',
        
      },
     
     
    ]
  },
  {
    caption: 'DHCP/MAC BINDING',
    columns: [
      { caption: 'plan', width: '1fr' },
      { caption: 'pricing', width: '170px' },
      { caption: 'discount', width: '170px' },
      { caption: 'features', width: '170px' },
      { caption: 'limit', width: '170px' },
      { caption: 'billed', width: '170px' },
    ],
    rows: [
      {
        plan: 'Startup Package',
        pricing: '<em> USD 0.62 per active user</em> p.m',
        // discount: 'Free from the 4th month',
        features: 'DHCP/STATIC IP',
        limit: 'Upto 1000 users',
        billed: 'Monthly',
        featuredPPPoE: true
      },
      {
        plan: 'Growth Stage',
        pricing: '<em>Contact us for custom billing</em> ',
        discount: 'custom',
        features: 'DHCP/STATIC IP',
        limit: 'Unlimited',
        billed: 'Quarterly',
        
      },
    ]
  },
  // {
  //   caption: 'Enterprise Plan',
  //   columns: [
  //     { caption: 'plan', width: '1fr' },
  //     { caption: 'pricing', width: '150px' },
  //     { caption: 'hotspot', width: '160px' },
  //     { caption: 'discount', width: '140px' },
  //     { caption: 'features', width: '110px' },
  //     { caption: 'tunnel', width: '100px' },
  //     { caption: 'financials', width: '115px' },
  //     { caption: 'reports', width: '100px' },
  //     { caption: 'limit', width: '152px' },
  //   ],
  //   rows: [
  //     {
  //       plan: 'Super Enterprise',
  //       pricing: '<em>Contact us for custom billing</em>',
  //       hotspot: '<em>5%</em> per transaction',
  //       discount: '',
  //       features: '<i>ALL</i>',
  //       tunnel: '<i>FREE</i>',
  //       financials: '<i>YES</i>',
  //       reports: '<i>YES</i>',
  //       limit: 'Unlimited Users',
  //     },
  //     {
  //       plan: 'Starter Package',
  //       pricing: '<em>100 USD</em> One off',
  //       fixed:'52 USD ',
  //       hotspot: '<em>5%</em> per transaction',
  //       discount: 'USD 20  for 200+ active cust..',
  //       features: '<i>DHCP/IP/tunnels/ppppoe/hotspot</i>',
  //       tunnel: '<i>YES</i>',
  //       financials: '<i>YES</i>',
  //       reports: '<i>YES</i>',
  //       limit: '1000',
  //     },
      
      
  //   ]
  // },
  {
    caption: 'NETPAP TUNNEL',
    columns: [
      { caption: 'plan', width: '1fr' },
      { caption: 'pricing', width: '170px' },
      { caption: 'billed', width: '170px' },
    ],
    rows: [
      {
        plan: 'Standard Netpap Package',
        pricing: 'USD <em>1.19</em>',
        limit: 'each Access Credential',
        billed: 'per Credential',
      },
    ]
  },
  {
    caption: 'NETPAP MONITORING',
    columns: [
      { caption: 'plan', width: '1fr' },
      { caption: 'pricing', width: '170px' },
      { caption: 'billed', width: '170px' },
    ],
    rows: [
      {
        plan: 'SNMP Powered Monitoring',
        pricing: '<em> USD 10per device</em>',
        limit: 'Unlimited Devices',
        billed: 'One off',
      },
      {
        plan: 'Centralized Network  Logging',
        pricing: 'USD<em>10</em> per device',
        limit: 'per device',
        billed: 'One off per device',
      },
    ]
  },
  {
    caption: 'A.C.S SYSTEM',
    columns: [
      { caption: 'plan', width: '1fr' },
      { caption: 'pricing', width: '170px' },
      { caption: 'billed', width: '170px' },
    ],
    rows: [
      {
        plan: 'AUTO CONFIGURATION SERVER & Zero Touch auto configuration.',
        pricing: 'USD<em>10</em> per CPE',
        limit: 'per CPE',
        billed: ' per CPE One-Off',
      },
    ]
  }
]

export default PricingPlans;
