import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import defaultImage from 'images/icon.png';
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, tags, title, image }) {

  const { pathname } = use_location();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            description
            author
          }
        }
      }
    `
  )

  const siteUrl = site.siteMetadata?.siteUrl
  const defaultTitle = site.siteMetadata?.title
  const metaImageUrl = `${siteUrl}${defaultImage}`
  const metaDescription = description || site.siteMetadata.description

  const seo = {
    title: !!title ? `${title} | ${defaultTitle}` : defaultTitle,
    keywords: Array.from(new Set([...defaultKeywords,...tags])),
    description: metaDescription,
    image: image || metaImageUrl,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet htmlAttributes={{ lang }}>

      <title>{seo.title}</title>
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      {seo.image && <meta name="image" content={seo.image} />}

      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />

      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={seo.url} />
      <meta property="twitter:title" content={seo.title} />
      <meta property="twitter:description" content={seo.description} />
      <meta property="twitter:image" content={seo.image} />

      <meta name="keywords" content={seo.keywords.join(',')} />

    </Helmet>
  )
}

function use_location() {

  if (typeof window === 'undefined') return { pathname: '' };

  const { pathname } = window?.location ?? { pathname: '' }

  return { pathname };

}

SEO.defaultProps = {
  lang: `en`,
  tags: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  image: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
}

const defaultKeywords = ['react','react-native','web development','web developer',
'app and website development','android with react native','anthony mwangi',
'anthony mwangi web developer','web developer in kenya']

export default SEO;
