import './styles.scss'
import React from 'react'
import PropTypes from "prop-types"

function HoverArrow() {
  return (
    <svg className="HoverArrow" width="12" height="12" viewBox="0 0 10 10" aria-hidden="true">
      <g fillRule="evenodd">
        <path className="HoverArrow__linePath" d="M0 5h7"></path>
        <path className="HoverArrow__tipPath" d="M1 1l4 4-4 4"></path>
      </g>
    </svg>
  )
}

function Button(props) {

  const CustomTag = `${props.component}`;

  return (
    <CustomTag className={`${props.className} btn`} href={props.url} target={`${props.target}`}>
      {props.children}
      <HoverArrow/>
    </CustomTag>
  );

}

Button.defaultProps = {
  url: '#',
  component: `a`,
  className: `button`,
  target: '_self',
  action: () => {}
}

Button.propTypes = {
  className: PropTypes.string,
  component: PropTypes.string,
  url: PropTypes.string,
  target: PropTypes.string,
  action: PropTypes.func
}

export default Button;
