import './_index.scss';
import React from "react"
import PropTypes from "prop-types"
import tawkTo from "tawkto-react"
const tawkToPropertyId = '6058d9f4067c2605c0bb1d50'
const tawkToKey = '1f1dh6pf0'

const Layout = ({ children }) => {

  React.useEffect(() => {
    tawkTo(tawkToPropertyId, tawkToKey);
  }, []);

  return (
    <>
      <main id='app'>{children}</main>
      <script src="https://cdn.jsdelivr.net/particles.js/2.0.0/particles.min.js"></script>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;
